
import { Component, Vue } from "vue-property-decorator";
import CustomerForm from "@/components/admin/forms/CustomerForm.vue";
import { ICustomer } from "@/types/customer";
import { Getter } from "vuex-class";

@Component({
  components: { CustomerForm }
})
export default class extends Vue {
  @Getter("customer/single")
  public customer!: ICustomer;
}
